@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');
.App {
  text-align: center;
  font-family: 'M PLUS Rounded 1c', sans-serif!important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.balloon1 {
  /* width: '120px'; */
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  /* background: #e0edff; */
  border: 1.5px solid #949292 ;
  border-radius: 15px;
}

.balloon1:before {
  content: "";
  position: absolute;
  top: 40%;
  left: -23px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-right: 12px solid #FFF;
  z-index: 2;
}
.balloon1::after{
  content: "";
  position: absolute;
  top: 40%;
  left: -26px;
  margin-top: -13px;
  border: 13px solid transparent;
  border-right: 13px solid #949292;
  z-index: 1;
}

.balloon1 p {
  margin: 0;
  padding: 0;
}